import { useEffect, useState } from 'react';

import Click from '../assets/images/click.jpg';
import ClickWhite from '../assets/images/click_white.png';

export const FloatingMessage = () => {
  const minPosVertical = 20;
  const maxPosVertical = 90;

  const minPosHorizontal = 10;
  const maxPosHorizontal = 90;

  const RandomVertical = () =>
    Math.floor(Math.random() * (maxPosVertical - minPosVertical + 1)) +
    minPosVertical;

  const RandomHorizontal = () =>
    Math.floor(Math.random() * (maxPosHorizontal - minPosHorizontal + 1)) +
    minPosHorizontal;

  const [position, Setposition] = useState({
    top: '40%',
    bottom: '40%',
    right: '40%',
    left: '40%',
  });
  useEffect(() => {
    const Intervall = setInterval(() => {
      Setposition({
        top: `${RandomVertical()}%`,
        bottom: `${RandomVertical()}%`,
        right: `${RandomHorizontal()}%`,
        left: `${RandomHorizontal()}%`,
      });
      return () => clearInterval(Intervall);
    }, 20000);
  }, []);

  return (
    <div className='FloatingMessage' style={position}>
      <h1>Click here! </h1>
      <img src={ClickWhite} style={{ maxHeight: '80%' }} />
    </div>
  );
};
