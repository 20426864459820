import React, { useState } from 'react';
import './App.css';
import { SlideShow } from './components/SlideShow';
import { FloatingMessage } from './components/FloatingMessage';

import { collection, addDoc } from 'firebase/firestore';

import logo from './assets/images/logo.png';
import { useEffect } from 'react';

import { db } from './utils/firebase';

function App() {
  const [mounted, SetMounted] = useState(false);
  useEffect(() => {
    try {
      addDoc(collection(db, 'locations/Polarsirkelsenteret/registrations'), {
        time: new Date(),
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }, []);

  return (
    <div className='App'>
      <div className='logo'>
        <img src={logo} />
      </div>
      <FloatingMessage />
      <SlideShow />
    </div>
  );
}

export default App;
